import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import { VueCookieNext } from 'vue-cookie-next'
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'
import lazyPlugin from 'vue3-lazy'
import { createMetaManager } from 'vue-meta'
window.$ = window.jQuery = require('jquery');

// axios.defaults.baseURL = 'http://41.95.192.128/'
const loadimage = require('./assets/img/Loading.gif')
const app = createApp(App);
app.use(router)
router.app = app
app.use(VueAxios, axios)
app.use(Toast)
app.use(VueCookieNext)
app.use(Vue3VideoPlayer, {
  lang: 'en'
})
app.use(createMetaManager())
app.use(lazyPlugin, {
  preLoad: 1.3,
  loading: loadimage,
  attempt: 1
})
app.mount('#app')
