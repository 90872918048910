import { createRouter, createWebHistory } from 'vue-router'
function lazyLoad(view){
  return() => import(`@/views/${view}.vue`)
}
function lazyPage(view){
  return() => import(`@/views/Page/${view}.vue`) 
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: lazyLoad('Home'),
  },
  {
    path: '/infographic',
    name: 'Infographic',
    component: lazyPage('Infographic')
  },
  {
    path: '/video/:id',
    name: 'Video',
    component: lazyPage('Video'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: lazyPage('About')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: lazyPage('Contact')
  },
  {
    path: '/policy',
    name: 'Policy',
    component: lazyPage('Policy')
  },
  {
    path: '/mix',
    name: 'Mix',
    component: lazyPage('Mix')
  },
  {
    path: '/health',
    name: 'Health',
    component: lazyPage('Health')
  },
  {
    path: '/technology',
    name: 'Technology',
    component: lazyPage('Technology')
  },
  {
    path: '/caricature',
    name: 'Caricature',
    component: lazyPage('Caricature')
  },
  {
    path: '/article',
    name: 'Article',
    component: lazyPage('Article')
  },
  {
    path: '/economie',
    name: 'Economie',
    component: lazyPage('Economie')
  },
  {
    path: '/press',
    name: 'Press',
    component: lazyPage('Press')
  },
  {
    path: '/sport',
    name: 'Sport',
    component: lazyPage('Sport')
  },
  {
    path: '/accident',
    name: 'Accident',
    component: lazyPage('Accident')
  },
  {
    path: '/readArticle/:id',
    name: 'ReadArticle',
    component: lazyPage('ReadArticle'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/User/Login.vue')
  },
  {
    path: '/loginUser',
    name: 'LoginUser',
    component: () => import(/* webpackChunkName: "loginUser" */ '../views/User/LoginUser.vue')
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    component: () => import(/* webpackChunkName: "unsubscribe" */ '../views/User/Unsubscribe.vue'),
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/:pathMatch(.*)*', 
    name: 'NotFound',
    component: lazyPage('NotFound'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

router.beforeEach((to, from, next ) => {
  const { $cookie } = router.app.config.globalProperties
   if(to.meta.IsAuth) {
    if($cookie.isCookieAvailable("msisdn") && $cookie.isCookieAvailable("status")) {
      next();
    } else {
      next(window.open("https://dsplp.sd.zain.com/?p=3885574267", "_self"));
      // next();
    }
   } else {
     next();
   }
})

export default router
